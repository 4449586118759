import { API_URL } from '../../env';
import axios from 'axios';
import { batch } from 'react-redux';

import {
    FETCH_INVOICE_REQUEST,
    FETCH_INVOICE_SUCCESS,
    FETCH_INVOICE_FAILURE,
    BAD_INVOICE_GUID,

    FETCH_INVOICE_PAYMENTSTATUS_REQUEST,
    
    FETCH_INVOICE_PAYMENT_FAILURE,


    FETCH_INVOICE_CC_URL_REQUEST,
    FETCH_INVOICE_CC_URL_SUCCESS,
    FETCH_INVOICE_CC_URL_FAILURE,


} from './';


export const fetchInvoiceBatch = (invoiceGuid) => async (dispatch) => {
    batch(() => {
        dispatch(fetchInvoice(invoiceGuid));
        // dispatch(fetchInvoiceServices(invoiceGuid));
    });
}

export const fetchInvoice = (invoiceGuid) => async (dispatch) => {
    dispatch({ type: FETCH_INVOICE_REQUEST});
    try {
        const response = await axios.get(`${API_URL}/api/order/invoice/${invoiceGuid}`);
        const { invoice, modules: invoiceModules } = response.data;
        const modules = invoiceModules.reduce((memo, module) => {
            const { selected_service_id, parent_selected_service_id, ...rest } = module;
            const formattedModule = { template_service_id: selected_service_id, parent_template_service_id: parent_selected_service_id, ...rest};
            return [...memo, formattedModule];
;        }, []);
        dispatch({ type: FETCH_INVOICE_SUCCESS, payload: { invoice, modules }});
    } catch (err) {
        if (err.response.status === 422) {
            dispatch({ type: BAD_INVOICE_GUID });
        } else {
            dispatch({ type: FETCH_INVOICE_FAILURE, payload: err.message});
        }
    }
}



export const getCreditCardUrl = (invoiceGuid) => async (dispatch) => {
    dispatch({ type: FETCH_INVOICE_CC_URL_REQUEST});
    try {
        const response = await axios.get(`${API_URL}/api/cc/url/${invoiceGuid}`);
        dispatch({ type: FETCH_INVOICE_CC_URL_SUCCESS, payload: response.data});
    } catch (err) {
        if (err.response.status === 422) {
            dispatch({ type: BAD_INVOICE_GUID });
        } else {
            dispatch({ type: FETCH_INVOICE_CC_URL_FAILURE, payload: err.message});
        }
    }
}

export const getCreditCardPaymentStatus = (invoiceGuid) => async (dispatch) => {
    dispatch({ type: FETCH_INVOICE_PAYMENTSTATUS_REQUEST});
    try {
        const response = await axios.get(`${API_URL}/api/cc/status/${invoiceGuid}`);
        if (response.data.payment_status !== 'unpaid') {
             dispatch(fetchInvoice(invoiceGuid));
        }
        
    } catch (err) {
        if (err.response.status === 422) {
            dispatch({ type: BAD_INVOICE_GUID });
        } else {
            dispatch({ type: FETCH_INVOICE_PAYMENT_FAILURE, payload: err.message});
        }
    }
}
