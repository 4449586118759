import { Outlet, Link, useLocation } from 'react-router-dom';
import { UOBar } from '../components';

const Legal = ( ) => {
    const { pathname } = useLocation();

    return (
        <>
        
        <UOBar />
        <div className="mt-3" />
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <img src="/intocareers-logo-web.svg" style={{width: '200px'}} alt="intoCareersLogo" />
                    <div className="mt-3" />
                    <ul className="nav nav-pills flex-column">
                        <li className="nav-item">
                            <Link to={`/legal/privacy`} className={`nav-link ${pathname === '/legal/privacy' ? ' active' : ''}`} activeclassname="active">Privacy Policy</Link>
                        </li>                        
                        <li className="nav-item">
                            <Link to={`/legal/terms`} className={`nav-link ${pathname === '/legal/terms' ? ' active' : ''}`} activeclassname="active">Terms</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-md-9">
                    <Outlet />
                </div>
            </div>
        </div>
        
        <div className="footer">
        <div className="container">
            <div className="row">
                <div className="col-footer col-md-4 col-xs-12">
                    <h3>
                        <em>into</em><strong>CAREERS</strong> Contact
                    </h3>
                    <p className="contact-us-details">

                        <b>Address:</b> 5258 University of Oregon, Eugene, Oregon 97403<br />
                        <b>Phone:</b> (541) 346-2374<br /> <b>Email:</b> <a
                            href="mailto:inquiries@intocareers.org">inquiries@intocareers.org</a>
                    </p>
                </div>

            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="footer-copyright">
                        &copy;
                        <script type="text/javascript">document.write(new Date().getFullYear())</script>
                        <em>into</em><strong>CAREERS</strong>. All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    </div>

        </>        
    )
}
export { Legal };