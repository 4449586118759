import {
    FETCH_FORM_REQUEST,
    FETCH_FORM_SUCCESS,
    FETCH_FORM_FAILURE,
    SUBMIT_ORDER_REQUEST,
    SUBMIT_ORDER_SUCCESS,
    SUBMIT_ORDER_FAILURE,
    UPDATE_INVOICE_LOCAL,
    BAD_INVOICE_FORM,

    UPDATE_MODULE,
    ADD_SITE,
    REMOVE_SITE,

    TRIGGER_VALIDATION,
    TRIGGER_VALIDATION_FOCUS,
    UPDATE_VALIDATION_STATE,
    SET_CLONE_BILLING,

    /**add school */
    UPDATE_SITEORDERED,
    REMOVE_SITEORDERED,
    ADD_OPTIONAL,
    REMOVE_ADDON,
    UPDATE_LICENSE_YEAR,

    /**MN old form */
    SUBMITMN_ORDER_REQUEST,
    SUBMITMN_ORDER_SUCCESS,
    SUBMITMN_ORDER_FAILURE,
} from '../actions';

import { initInvoiceState } from '../initialStates';
const initialState = {
    isLoading: false,
    invoice: initInvoiceState,
    modules: [],
    moduleBank: [],
    payment_methods: [],
    errors: [],
    /**
     * add new array variable to hold 
     * each school or site
     * schoolName, enrollment, service module, and optional services
     */
    siteOrdered: [],
    isValidForm: true,
    submitting: false,
    cloneBilling: true, // uses billing info for service contact
    validationTestNumber: 0,
    validationFocusNumber: 0,
    validationState:
    {
        billing: false,
        requestor: false,
        termsAccepted: false
    }
};

const orderReducer = ((state = initialState, action) => {
    switch (action.type) {
        case FETCH_FORM_REQUEST:
            return { ...state, isLoading: true };
        case FETCH_FORM_SUCCESS:
            const modules = action.payload.invoice.is_site_form ? [] : action.payload.modules;
            return { ...state, ...action.payload, isLoading: false, modules, moduleBank: action.payload.modules };
        case FETCH_FORM_FAILURE:
            return { ...state, error: action.payload, isLoading: false };

        case SUBMIT_ORDER_REQUEST:
            return { ...state, submitting: true };
        case SUBMIT_ORDER_SUCCESS:
            return { ...state, invoice: { ...state.invoice, ...action.payload }, submitting: false };
        case SUBMIT_ORDER_FAILURE:
            return { ...state, error: action.payload, submitting: false };
        case UPDATE_INVOICE_LOCAL:
            return { ...state, invoice: { ...state.invoice, ...action.payload } };
        case ADD_SITE: // for site based orders
            return { ...state, modules: [...state.modules, action.payload] };
        case REMOVE_SITE: // for site based orders
            return { ...state, modules: state.modules.filter(x => x.parent_template_service_id !== action.payload && x.template_service_id !== action.payload) };
        case UPDATE_MODULE: // for module base orders
            const newModulesState = state.modules.reduce((memo, item) => {
                if (item.template_service_id === action.payload.template_service_id) {
                    return [...memo, action.payload];
                } else {
                    return [...memo, item];
                }
            }, []);
            return { ...state, modules: newModulesState };
        case BAD_INVOICE_FORM:
            return { ...state, isValidForm: false };
        case UPDATE_VALIDATION_STATE:
            return { ...state, validationState: { ...state.validationState, ...action.payload } };
        case TRIGGER_VALIDATION:
            return { ...state, validationTestNumber: state.validationTestNumber + 1 };
        case TRIGGER_VALIDATION_FOCUS:
            return { ...state, validationFocusNumber: state.validationFocusNumber + 1 };
        case SET_CLONE_BILLING:
            return { ...state, cloneBilling: action.payload };
        case UPDATE_SITEORDERED:
            if (state.siteOrdered.filter((item) => item.schoolName === action.payload.schoolName
                && item.template_service_id === action.payload.template_service_id).length === 0) {
                return { ...state, siteOrdered: [...state.siteOrdered, action.payload] }
            }
            else
                return state;
        case REMOVE_SITEORDERED:
            return {
                ...state,
                siteOrdered: state.siteOrdered.filter(x => x.siteId !== action.payload.siteId)
            }

        case ADD_OPTIONAL:
        //find site ordered    
            const siteInOrder =state.siteOrdered.find(site=>site.siteId === action.payload.siteId);
            // if (siteInOrder.services)
            if (siteInOrder && !!siteInOrder.siteId ){
               const updatedSiteInOrder = Object.assign({}, siteInOrder, { addOns: [...siteInOrder.addOns, action.payload] });
                return { ...state, siteOrdered: [...state.siteOrdered.filter(x => x.siteId !== action.payload.siteId), updatedSiteInOrder]};
            }
            else{
                return state;
            }
        case REMOVE_ADDON:    
            const siteOrder = state.siteOrdered.find(site => site.siteId === action.payload.siteId);

            if (siteOrder && !!siteOrder.siteId) {
                const updatedSiteInOrder = Object.assign({}, siteOrder, { addOns: siteOrder.addOns.filter(x => x.template_service_id!==action.payload.addOn_id) });
                return { ...state, siteOrdered: [...state.siteOrdered.filter(x => x.siteId !== action.payload.siteId), updatedSiteInOrder] };
            }
            return state;
        case UPDATE_LICENSE_YEAR:

            if (action.payload) {
                return { ...state, siteOrdered: [...state.siteOrdered.map(x => x.license_term !== action.payload.license_term ? { ...x, license_term: action.payload.license_term } : x)] }
            }
            else
                return state;
        default:
            return state;
    }
});

export default orderReducer;

