const Terms = () => {
    return (
        <>
        <div className="lead">
            <h1>
            Terms of Use
            </h1>
        </div>
        
        <div className="page-header">
            <h3>1. General Terms</h3>
        </div>

        <p>
            <strong>1.1</strong> The University of Oregon has developed
            and/or obtained rights to intellectual property that comprises
            the Career Information System (hereinafter "CIS"). By using CIS,
            you agree to be bound by the following Terms Conditions. If you
            do not agree to these terms, do not use CIS.
        </p>

        <div className="page-header term">
            <h3>2. Use of CIS</h3>
        </div>
        <p>
            <strong>2.1</strong> The University of Oregon grants permission
            to use CIS for personal benefit to research career options and
            create personal career development plans. Printing individualized
            and selected information for use in counseling and instructional
            programs provided by schools and agencies is permitted and
            encouraged. Using the content for commercial gain is prohibited.
        </p>


        <div className="page-header term">
            <h3>3. Copyright</h3>
        </div>
        <p>
            <strong>3.1</strong> CIS contains valuable proprietary products
            of the University of Oregon and other contributors embodying
            substantial creative efforts and confidential information, ideas,
            and expressions. All of CIS is protected by U.S. copyright laws
            and by international treaties. The right to use CIS does not
            transfer title, copyright, or ownership of CIS to you or any
            other person. 
        </p>

        <div className="page-header term">
            <h3>4. Warranty Disclaimer</h3>
        </div>
        <p>
            <strong>4.1</strong> CIS is provided on an "AS IS" basis without
            warranties of any kind, either express or implied, including but
            not limited to warranties of accuracy, completeness, currency,
            noninfringement, or title or of merchantability or of fitness for
            a particular purpose, other than those warranties which are
            implied by and incapable of exclusion, restriction or
            modification under applicable law. In no event shall University
            of Oregon be liable to you or any other person for loss of
            business or profits, or for any indirect, special, incidental or
            consequential damages, arising out of the use of, or inability to
            use CIS, even if University of Oregon was previously advised of
            the possibility of such damages, or for any other legal claim by
            you or any other person.
        </p>
        </>
    )
}

export { Terms };