import { Outlet, useParams } from 'react-router-dom';
import { CardFooter, } from 'react-bootstrap';
import { ShoppingCartBar, ServiceList } from '../components';
import { AddSchoolOrganization } from "../components";
import { useSelector } from 'react-redux';

const Services = () => {
       const { moduleId } = useParams();
    const ModuleServices = () => {
        //get year for footer
        const year = new Date().getFullYear();

        const orderModules = useSelector((state) => state.order.modules);
        const parentModule = orderModules.find(x => x.template_service_id === +moduleId);
        return (
            
             <>
                {parentModule.appType === 'Elementary' &&  
                    <div>
                        <a href='https://careertrek.org/order' target='_blank' rel="noreferrer">CareerTrek Order Form</a>
                    </div>
                }
                {parentModule.appType!=='Elementary' &&  
                <div>       
                <div className='enrollContainer'>
                    <div className="enrollTitle">
                        Place Order
                    </div>
                    <div className="enrollTitle itemInCard">
                       
                        <div className='text-center'>Items in Cart</div>
                    </div>
                </div>
                <div className='enrollMain'>
                    <div className='col-md-12 col-sm-12 enrollMainDiv school'>
                        <AddSchoolOrganization />
                    </div>
                    <div className='col-md-12 col-sm-12 enrollMainDiv orderDetail'>
                        <Outlet />
                    </div>
                
                </div></div>
                }
                <CardFooter className='footer text-right mt-4 py-2 bg-color-gray '>
                    ©{year.toString()} intoCareers, University of Oregon. All rights reserved.
                </CardFooter>
                
            </>
        )
    }

    return (
        <div className="d-flex flex-column">

            <>
                <ShoppingCartBar customLink='cart' customText='Available Services' />
              
                <div >
                    <ServiceList />
                </div>
            
                <div className="mt-3"></div>
                {(typeof(moduleId) !=='undefined' && moduleId!=='') ?
                <ModuleServices /> 
                :<h5>Please select a product or service to continue.</h5>
                }
            </>
        </div>
    )
}
export { Services };
