import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

//defined in .env file
import { CT_ORDER_URL } from '../../env';

const ServiceModules = () => {
    const { modules, invoice } = useSelector((state) => state.order);

    const [selected, updateSelected] = useState();
    const handleSelect = (template_service_id, service_id) => {
        if (service_id === 2 ) {
            window.open(CT_ORDER_URL, '_blank');
        }
        else {
            updateSelected(template_service_id);
        }
    }
    const divStyle = {
        width: '15rem',
        textAlign: 'center'
    };

    return (
        <Row>
            {modules.filter(item => !item.parent_template_service_id).map((module, index) => (
                <Col key={`service-${index}-item`}>
                    <Link to={`/${invoice.url_key}/services/${module.template_service_id}`} onClick={() => handleSelect(module.template_service_id, module.service_id)}>
                        <div className={'card ' + (selected === module.template_service_id ? 'bg-light' : '')} style={divStyle}>
                            {module.quantity > 0 && (
                                <div className="card-img-overlay">
                                    <div className="sr-only">Module is in shopping cart</div>
                                    <i className="fa fa-lg fa-shopping-cart text-success"></i>
                                </div>
                            )}
                            <div className="card-body d-flex flex-column justify-content-between">
                                
                                
                                <div className="card-image">
                                        <img src={module.service_logo_src} alt={`logo for ${module.service_name} module ${module.service_id}`} />
                                </div>
                                
                            </div>
                        </div>
                    </Link>
                </Col>
            ))}
        </Row>

    )
}
export { ServiceModules }