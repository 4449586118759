import orderReducer from './orderReducer';
import invoiceReducer from './invoiceReducer';
import { combineReducers } from "redux";

const rootReducer = combineReducers ({
    order: orderReducer,
    invoice: invoiceReducer

});

export default rootReducer;