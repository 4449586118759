import { useSelector } from 'react-redux';
// import CurrencyFormat from 'react-currency-format';
import { useNavigate } from 'react-router-dom';
import { getSitesTotals } from  '../../Utils/invoiceTotals';
import { formatCurrency } from "../../Utils/formatCurrency";
const ShoppingCartBar = ({customLink, totalType}) => {
    const navigate = useNavigate();
    const {modules: services, invoice, siteOrdered } = useSelector((state) => state.order);

    const { sitesTotal } = getSitesTotals(invoice, services, siteOrdered);
    
    const formImageStyle = {
        height: '8rem'        
    };


    const ShoppingCartButton = () => {
        return (
            <>
            {totalType === 'total' ? (
                <button 
                    type="button"
                    style={{minWidth: '8rem'}}
                    onClick={handleNav}
                        disabled={sitesTotal < 1}
                    className="btn d-flex align-items-center d-flex-grow-1 justify-content-between btn-secondary">                        
                        {formatCurrency(sitesTotal)}
                        <div><i className="fa fa-lg fa-cart-plus" aria-hidden="true"></i></div>
                </button>                
            ) : (
                <button 
                    type="button"
                    style={{minWidth: '8rem'}}
                    onClick={handleNav}
                            disabled={sitesTotal < 1}
                    className="btn d-flex align-items-center d-flex-grow-1 justify-content-between btn-success">
                            {formatCurrency(sitesTotal)}
                        <div><i className="fa fa-lg fa-cart-plus" aria-hidden="true"></i></div>
                </button>                                
            )}
            </>
        )
    }




    const handleNav = () => {        
        navigate(`/${invoice.url_key}/${customLink}`);
    }
    return (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center flex-wrap pt-4">
                <img
                    style={formImageStyle} 
                    src={invoice.logo_src} alt={`logo for order form`}
                />
                <div className="d-flex-grow-1"></div>
                <ShoppingCartButton />
            </div>
            <div>
                <hr />
            </div>            
        </div>
    );
}
export { ShoppingCartBar };