import { API_URL } from '../../env';
import axios from 'axios';
import PropTypes from 'prop-types'
import {
    FETCH_FORM_REQUEST,
    FETCH_FORM_SUCCESS,
    FETCH_FORM_FAILURE,
    SUBMIT_ORDER_REQUEST,
    SUBMIT_ORDER_SUCCESS,
    SUBMIT_ORDER_FAILURE,
    UPDATE_INVOICE_LOCAL,

    UPDATE_MODULE,
    ADD_SITE,
    REMOVE_SITE,

    BAD_INVOICE_FORM,
    TRIGGER_VALIDATION,
    TRIGGER_VALIDATION_FOCUS,
    UPDATE_VALIDATION_STATE,
    SET_CLONE_BILLING,

    /**add school/organization */
    UPDATE_SITEORDERED,
    REMOVE_SITEORDERED,
    ADD_OPTIONAL,
    REMOVE_ADDON,
    UPDATE_LICENSE_YEAR,
    /**MN old form */
    SUBMITMN_ORDER_REQUEST,
    SUBMITMN_ORDER_SUCCESS,
    SUBMITMN_ORDER_FAILURE,

} from './actionTypes';

export const submitOrder = () => async (dispatch, getState) => {
    const { invoice, modules, siteOrdered } = getState().order;
    dispatch({ type: SUBMIT_ORDER_REQUEST });
    try {
        const response = await axios.post(`${API_URL}/api/order/submit`, { invoice, modules, siteOrdered });
        dispatch({ type: SUBMIT_ORDER_SUCCESS, payload: response.data });
    } catch (err) {
        dispatch({ type: SUBMIT_ORDER_FAILURE, payload: err.message });
    }
}

export const fetchOrderForm = (urlKey) => async (dispatch) => {
    dispatch({ type: FETCH_FORM_REQUEST });
    try {
        const response = await axios.get(`${API_URL}/api/form/key/${urlKey}`);
        dispatch({ type: FETCH_FORM_SUCCESS, payload: response.data });
    } catch (err) {
        if (err.response.status === 422) {
            dispatch({ type: BAD_INVOICE_FORM });
        } else {
            dispatch({ type: FETCH_FORM_FAILURE, payload: err.message });
        }
    }
}

export const updateInvoiceLocal = (payload) => (dispatch) => {
    dispatch({ type: UPDATE_INVOICE_LOCAL, payload });
}

export const updateLicenseYear = (payload) => (dispatch) => {
    dispatch({ type: UPDATE_LICENSE_YEAR, payload });
}

export const updateModule = (payload) => (dispatch) => {
    dispatch({ type: UPDATE_MODULE, payload });
}
updateModule.propTypes = {
    template_service_id: PropTypes.number,
    quantity: PropTypes.number
}

export const updateSiteOrdered = (payload) => (dispatch) => {
    dispatch({ type: UPDATE_SITEORDERED, payload });
}
updateSiteOrdered.propTypes = {
    schoolName: PropTypes.string,
    enrollment: PropTypes.number,
    template_service_id: PropTypes.number,
    quantity: PropTypes.number
}
export const removeSiteOrdered = (payload) => (dispatch) => {
    dispatch({ type: REMOVE_SITEORDERED, payload });
}

export const addSite = (siteServices) => (dispatch) => {
    siteServices.forEach(service => {
        dispatch({ type: ADD_SITE, payload: service });
    })
}

export const removeSite = (template_service_id) => (dispatch) => {
    dispatch({ type: REMOVE_SITE, payload: template_service_id });
}




export const triggerValidation = () => (dispatch) => {
    dispatch({ type: TRIGGER_VALIDATION });
}
export const triggerValidationAndFocus = () => (dispatch) => {
    dispatch({ type: TRIGGER_VALIDATION_FOCUS });
}


export const reportInValidation = (payload) => (dispatch, getState) => {
    dispatch({ type: UPDATE_VALIDATION_STATE, payload });
}


export const updateCloneBilling = (payload) => (dispatch) => {
    dispatch({ type: SET_CLONE_BILLING, payload });
}


// export const validateInput = () => (dispatch, getState) => {
//     const { invoice } = getState().order;
    
// }


export const addOptional = (payload) => (dispatch) => {
    dispatch({ type: ADD_OPTIONAL, payload});
}
export const removeAddOn = (payload) => (dispatch) => {
    dispatch({ type: REMOVE_ADDON, payload });
}

export const submitOrderMN = () => async (dispatch, getState) => {
    const { invoice, modules } = getState().order;
    dispatch({ type: SUBMIT_ORDER_REQUEST });
    try {
        const response = await axios.post(`${API_URL}/api/order/submitMN`, { invoice, modules });
        dispatch({ type: SUBMIT_ORDER_SUCCESS, payload: response.data });
    } catch (err) {
        dispatch({ type: SUBMIT_ORDER_FAILURE, payload: err.message });
    }
}

export * from './actionTypes';


