import {
    BAD_INVOICE_GUID,
    FETCH_INVOICE_REQUEST,
    FETCH_INVOICE_SUCCESS,
    FETCH_INVOICE_CC_URL_SUCCESS,



} from '../actions';


import { initInvoiceState } from '../initialStates';
const initialState = {
    isLoading: true,
    isValidInvoice: true,
    invoice: initInvoiceState,
    modules: [],    
    errors: [],
    ccUrl: {
        statusCheck: '',
        url: ''
    },
};


const invoiceReducer = ((state = initialState, action) => {
    switch (action.type) {
        case BAD_INVOICE_GUID:
            return {...state,  isValidInvoice: false };          
        case FETCH_INVOICE_REQUEST:            
            return {...state, isLoading: {...state.isLoading, invoice: true }}; 
        case FETCH_INVOICE_SUCCESS:            
            return {...state, ...action.payload, isLoading:  false  };
        case FETCH_INVOICE_CC_URL_SUCCESS:
            return {...state, ccUrl: action.payload };        
        default:            
            return state;
    }
});

export default invoiceReducer;
