import { useSelector, useDispatch } from 'react-redux';
import { updateCloneBilling, triggerValidation } from '../store/actions';
import { ShoppingCartBarMN, Billing, Requestor, PaymentMethod, SubmitOrderMN } from '../components';
const CheckoutMN = () => {
    const dispatch = useDispatch();

    const { cloneBilling } = useSelector((state) => state.order);
    const handleCloneBilling = () => {
        dispatch(updateCloneBilling(!cloneBilling));
        dispatch(triggerValidation());
    }

    return (
        <div className="d-flex flex-column">

            <ShoppingCartBarMN customLink='cart' customText='Checkout' totalType='total' />

            <div className="mt-3"></div>



            <div className="card p-0">
                <div className="card-header">
                    <span className="card-title">Submit Order</span>
                </div>

                <div className="card-body">

                    <div className="d-md-flex flex-row justify-content-between">
                        <div className="col-md-6 col-sm-12 d-flex flex-column">

                            <Billing />

                            <div className="mt-3"></div>

                            <div className="d-flex flex-row justify-content-between">
                                <b>Service Contact</b>
                                {cloneBilling ? (
                                    <div>
                                        same as billing - <span className='decorated-link' onClick={handleCloneBilling}>change</span>
                                    </div>
                                ) : (
                                    <div>
                                        different than billing - <span className='decorated-link' onClick={handleCloneBilling}>change</span>
                                    </div>
                                )}
                            </div>

                            {!cloneBilling && (
                                <>
                                    <div className="mt-3"></div>
                                    <Requestor />
                                </>
                            )}
                        </div>
                        <div className="col-md-6 col-sm-12 d-flex flex-column justify-content-between" >
                            <div className="mt-3"></div>
                            <PaymentMethod />
                            <SubmitOrderMN />
                        </div>
                    </div>

                </div>

            </div>


            <div className="mt-3"></div>

        </div>
    );
}

export { CheckoutMN };


