import { Outlet } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { TermDates, ServiceModules, ShoppingCartBarMN } from '../components';
const ServicesMN = () => {

    const ModuleServices = () => {
        return (
            <Row>
                <Col md={6} sm={6} xs={12}>
                    <div className="lead">
                        Modules
                    </div>
                    <ServiceModules />
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Outlet />
                </Col>
            </Row>
        )
    }

    return (
        <div className="d-flex flex-column">

            <>
                <ShoppingCartBarMN customLink='cart' customText='Available Services' />
                <TermDates />
                <div className="mt-3"></div>
                <ModuleServices />
            </>
        </div>
    )
}
export { ServicesMN };
