const UOBar = () => {
    return (
        <div className="uoregon-bar pt-1 pb-1">            
            <div className="container">
                <div className="d-flex d-flex-row justify-content-between align-items-center">                
                    <a href="https://www.uoregon.edu/" title="University of Oregon"><img src="/uologo.png" width="195" height="35" alt="University of Oregon" /></a>            
                    <div>
                        <a className="uonav" href="https://www.uoregon.edu">UO Home</a>&nbsp; | &nbsp; <a href="https://education.uoregon.edu" className="uonav">College of Education</a>
                    </div>
                </div>
            </div>
        </div>         
    )
}
export { UOBar };