import { useSelector } from 'react-redux';
const ModuleChoose = () => {
    const { invoice } = useSelector((state) => state.order);
    return (
        <>
        <div>Select Module</div>
        <div className="mt-3"></div>
        <div>{invoice.service_landing}</div>
        </>
    );
}

export { ModuleChoose };