import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TermDates, ShoppingCartBar, SiteServices, AddSite } from '../components';


const Sites = () => {
    const { invoice, moduleBank, modules } = useSelector((state) => state.order);
    const [showNewButton, updateShowNewButton] = useState(false);

    useEffect(() => {
        if (modules.length < 1) {
            updateShowNewButton(true);
        } else {
            updateShowNewButton(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [modules]);
    
    // const navigate = useNavigate();
    // const handleRoute = () => {
    //     navigate(`/${invoice.url_key}/sites`);
    // }

    // const IsSite = () => {
    //     return (
    //         <div className="d-flex flex-column align-items-center">                
    //             <h4 className="text-center">Is this order for a school or school district?</h4>
    //             <div className="mt-3"></div>
    //             <div className="d-flex flex-row justify-content-between">
    //                 <button type="button" className="btn btn-lg btn-primary" onClick={handleRoute}>Yes</button>
    //                 <div className="p-2"></div>
    //                 <button type="button" className="btn btn-lg  btn-primary">No</button>
    //             </div>
    //         </div>
    //     );
    // };

    return (
        <div className="d-flex flex-column">

            <div className="mt-3"></div>
            <ShoppingCartBar logo={invoice.logo_src} customLink='cart' />
            {/* <OrderFormTitleBar logo={invoice.logo_src} formName={invoice.template_wrapper_name} /> */}
            
            
            <TermDates />

            <div className="mt-3"></div>

            {showNewButton ? (
                <>
                {modules.length > 0 && (
                    <div className="d-flex flex-row-reverse">
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => updateShowNewButton(false)}
                        >Cancel</button>
                    </div>
                )}
                <AddSite moduleBank={moduleBank} />
                </>
            ) : (
                <div className="d-flex flex-row-reverse">
                    <button
                        type="button"
                        className="btn btn-primary"                
                        onClick={() => updateShowNewButton(true)}
                    >Add</button>
                </div>

            )}


            <div>
                <hr />
            </div>
            
            <SiteServices modules={modules} />

            {/* <div className="adaptive-centerish-content">                        
                <IsSite />
            </div> */}
        </div>
        
    )
};

export { Sites };