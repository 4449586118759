import { useEffect, createRef, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateInvoiceLocal, triggerValidation, reportInValidation } from '../../store/actions';

const fieldsToCopy = [
    { source: 'bill_attn', target: 'requestor_name' },
    { source: 'bill_organization', target: 'requestor_organization' },
    { source: 'bill_email', target: 'requestor_email' },
    { source: 'bill_phone', target: 'requestor_phone' },
];

const Billing = (props ) => {

    const {invoice, validationTestNumber, validationFocusNumber, cloneBilling } = useSelector((state) => state.order);
    const dispatch = useDispatch();
    const form = createRef();

    const { value, onChange } = props;
    const [cursor, setCursor] = useState(null);
    const ref = useRef(null);

    const [error, setError] = useState(null);
    
    useEffect(() => {
      const input = ref.current;
      //console.log("cursor: ", cursor )
      if (input){ 
        setTimeout(() => {
          input.setSelectionRange(cursor, cursor);
        },20);
      }
    }, [ref, cursor, value]);

    // runs truthy for required fields
    const reportFormState = () => {
        const formState = form.current.checkValidity();
        dispatch(reportInValidation( { billing: formState }))
        dispatch(reportInValidation( { requestor: formState }))

    }
    // runs truthy and sets focus
    const focusValidation = () => {
        form.current.reportValidity();
    }

    /**validate email address */
    const isValidEmail=(email)=>{
      return /\S+@\S+\.\S+/.test(email); 
    }
    
    const handleCursorKeys =(e)=>{
       setCursor(e.target.selectionStart - 1);
    }

    const handleChange = (e) => {    
        if (e.target.name === "bill_email")
        {
            if (!isValidEmail(e.target.value) && e.target.value!=="") {
              setError("Email is invalid!");
            } else {
              setError(null);
            }
        }
          //console.log("current value: ", e.current.target.value);
          dispatch(updateInvoiceLocal({ [e.target.name]: e.target.value }));
        // handle cloning here to avoid race condition
        if (cloneBilling) {
            const cloneMap = fieldsToCopy.find(x => x.source === e.target.name);
            if (cloneMap) {
                dispatch(updateInvoiceLocal({[cloneMap.target]: e.target.value }));
            }
        }
        // Run the full check to make sure that the Requestor info is checked
        dispatch(triggerValidation());
        
        setCursor(e.target.selectionStart);
        
        //update current cursor position
        onChange && onChange(e);

    }

    const copyBilling = () => {        
        fieldsToCopy.forEach(item =>  {
            dispatch(updateInvoiceLocal({[item.target]: invoice[item.source] || '' }));
        })
    }    

    useEffect(() => {
        if (cloneBilling) {
            copyBilling();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cloneBilling]);


    useEffect(() => {
        if (validationTestNumber > 0) {
            reportFormState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationTestNumber]);

    useEffect(() => {
        if (validationFocusNumber > 0) {
            focusValidation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationFocusNumber]);    

    

    return (
      <>
        <form
          ref={form}
          onBlur={reportFormState}
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="row">
            <div className="col-12">
              <div className="form-group has-warning">
                <label className="control-label" htmlFor="billAttn">
                  <b>Billing Contact:</b>
                </label>
                <input
                  id="billAttn"
                  required={true}
                  className="form-control"
                  name="bill_attn"
                  value={invoice.bill_attn}
                  onChange={handleChange}
                  ref={ref}
                  onKeyDown={handleCursorKeys}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="billOrg">Organization Name:</label>
                <input
                  id="billOrg"
                  className="form-control"
                  name="bill_organization"
                  required={true}
                  value={invoice.bill_organization}
                  onChange={handleChange}
                  ref={ref}
                  onKeyDown={handleCursorKeys}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <label htmlFor="billEmail">Email:</label>
                <input
                  id="billEmail"
                  className="form-control"
                  required={true}
                  name="bill_email"
                  //type="email"
                  value={invoice.bill_email}
                  onChange={handleChange}
                  ref={ref}
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                  onKeyDown={handleCursorKeys}
                />
                {error && <span style={{ color: "red" }}>{error}</span>}
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <label htmlFor="billPhone">Phone:</label>
                <input
                  id="billPhone"
                  className="form-control"
                  name="bill_phone"
                  type="tel"
                  required={true}
                  value={invoice.bill_phone}
                  onChange={handleChange}
                  ref={ref}
                  onKeyDown={handleCursorKeys}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="billAddress">Address:</label>
                <input
                  id="billAddress"
                  className="form-control"
                  name="bill_address"
                  required={true}
                  value={invoice.bill_address}
                  onChange={handleChange}
                  ref={ref}
                  onKeyDown={handleCursorKeys}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="billCity">City:</label>
                <input
                  id="billCity"
                  className="form-control"
                  name="bill_city"
                  required={true}
                  value={invoice.bill_city}
                  onChange={handleChange}
                  ref={ref}
                  onKeyDown={handleCursorKeys}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <label htmlFor="billState">State:</label>
                <input
                  id="billState"
                  className="form-control"
                  name="bill_state"
                  required={true}
                  value={invoice.bill_state}
                  onChange={handleChange}
                  ref={ref}
                  onKeyDown={handleCursorKeys}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <label htmlFor="billZip">Zip:</label>
                <input
                  id="billZip"
                  className="form-control"
                  name="bill_zip"
                  required={true}
                  value={invoice.bill_zip}
                  onChange={handleChange}
                  ref={ref}
                  onKeyDown={handleCursorKeys}
                  //onKeyUp={handleLastInput}
                />
              </div>
            </div>
            <div className="col-md-1 col-sm-1 col-xs-12">
              <div className="form-group">
                <input
                  id="fakeCOl"
                  className="form-control hiddenInput"
                  name="fakeCol"
                  required={false}
                  value={invoice.fake_col}
                  onChange={handleChange}
                  ref={ref}
                  //type='hidden'
                  //onKeyDown={handleCursorKeys}
                  //onKeyUp={handleLastInput}
                  disabled={true}
                  width={0}
                  height={0}
                />
              </div>
            </div>
          </div>
        </form>
      </>
    );
}

export { Billing };