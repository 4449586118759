import { useEffect, useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
// import CurrencyFormat from 'react-currency-format';
import {  Form, Button } from 'react-bootstrap';
import { addSite } from '../../store/actions';
import {formatCurrency} from "../../Utils/formatCurrency";

const initialState = {
    parent: {
        enrollment: null
    },
    services: []
};

const getTierPrice = (service, enrollment) => {
    const myTier = service.tiers.find(x => enrollment >= x.tier_range_bottom && enrollment <= x.tier_range_top) || { tier_price: service.price};
    return myTier.tier_price;
}

const reducer = (state, action) => {
    switch (action.type) {    
        case 'ADD_PARENT':
            const min = 1;
            const max = 500000;
            const template_service_id =  Math.floor(Math.random() * (max - min) + min);
            return {...state, parent: {...action.payload, template_service_id, quantity: 1 }, services: []  };
        case 'UPDATE_PARENT':
            if (Object.keys(action.payload).includes('enrollment')) {
                const { enrollment } = action.payload;
                return {
                    ...state, 
                    parent: {...state.parent, ...action.payload},
                    services: state.services.reduce((memo, item) => { 
                        const price = getTierPrice(item, enrollment);
                        return [...memo, {...item, price}];
                    }, [])
                };
            }            
            else {
                return {...state,  parent: {...state.parent, ...action.payload} };
            }                    
        case 'ADD_CHILD':
            if (state.services.find(item => item.template_service_id === action.payload.template_service_id)) {
                return { ...state, services: state.services.filter(item => item.template_service_id !== action.payload.template_service_id) };    
            } else {
                const price = getTierPrice(action.payload, state.parent.enrollment);            
                return { ...state, services: [...state.services, {...action.payload, price, quantity: 1, parent_template_service_id: state.parent.template_service_id }] };
            }
        case 'REMOVE_CHILD':
            return { ...state, services: state.services.filter(item => item.template_service_id !== action.payload.template_service_id) };
        default:
            throw new Error();
    }
}


const AddSite = ({moduleBank}) => {
    const dispatch = useDispatch();
    const [newSite, updateNewSite] = useReducer(reducer, initialState);
    const [validated, setValidated] = useState(false);

    const toggleChild = (service) => {
        updateNewSite({ type:'ADD_CHILD', payload: service });
    }
    const handleUpdateParent = (e) => {
        const payload = e.target.name === 'enrollment' ? {[e.target.name]: parseInt(+e.target.value, 10)} : {[e.target.name]: e.target.value};
        updateNewSite({type:'UPDATE_PARENT', payload});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
          event.stopPropagation();          
        }

        setValidated(true);
                
        if(form.checkValidity()) {
            dispatch(addSite([newSite.parent, ...newSite.services]));
            seedParent();
        }
    }

    const seedParent = () => {
        const myParent = moduleBank.find(x => !x.parent_template_service_id);
        updateNewSite({type:'ADD_PARENT', payload: {...myParent, service_name: '', quantity: 1} });
    }

    useEffect(() => {        
        seedParent();
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [moduleBank]);    

    return (


    <div className="jumbotron mt-3 pt-3 pb-3">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col">
                <Form.Group controlId='serviceName'>
                        <Form.Label>Site Name</Form.Label>
                        <Form.Control 
                            type="text"  
                            name="service_name"
                            required                            
                            placeholder='name of school or organization'
                            value={newSite.parent?.service_name || ''}
                            onChange={handleUpdateParent}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter full name
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>

                <div className="col-md-2">

                    <Form.Group controlId='enrollment'>
                        <Form.Label>Enrollment</Form.Label>
                        <Form.Control 
                            type="text"
                            pattern="[1-9]*"
                            name="enrollment"
                            required       
                            placeholder='annual enrollment'
                            value={newSite.parent.enrollment | null}                            
                            onChange={handleUpdateParent}
                            />
                    </Form.Group>
                </div>                

                <div className="col offset-md-1">
                Services:       
                    {moduleBank.filter(item => item.parent_template_service_id).map((service, index) => (
                        
                        <div key={index} className="d-flex flex-row justify-content-between">
                            <div>
                                <input 
                                    id="termsAccepted" 
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={newSite.services.find(x => x.template_service_id === service.template_service_id) || false}
                                    onChange={()=>toggleChild(service)} name="services" />
                                {service.service_name}
                            </div>
                            {newSite.parent.enrollment > 0 && (
                                <div>
                                    {/* <CurrencyFormat value={getTierPrice(service, newSite.parent.enrollment)} displayType={'text'} thousandSeparator={true} prefix={'$'} />                                   */}
                                    {formatCurrency(getTierPrice(service, newSite.parent.enrollment))}
                                </div>
                            )}
                            
                        </div>
                    ))}                
                </div>
            </div>
            <div className="mb-auto p-2"></div>
            <div className="row">
                <Button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={newSite.services.length < 1 || !newSite.parent.service_name }
                >Save
                </Button>
            </div>
        </Form>
    </div>


    )
}

export { AddSite };