const today = new Date();
const academic_year = ((today.getMonth() + 1) <= 7) ? today.getFullYear() : today.getFullYear() + 1;
const initInvoiceState = {
    academic_year,
    bill_address: '',
    bill_attn: '',
    bill_city: '',
    bill_email: '',
    bill_organization: '',
    bill_phone: '',
    bill_state: '',
    bill_zip: '',
    cis_logo: '',
    cis_logo_height: null,
    cis_logo_xoffset: null,
    cis_logo_yoffset: null,    
    date_created_display: '',
    date_created: new Date(),
    date_due_display: '',
    date_due: new Date(),
    date_payment_confirmed: null,
    discount: null,
    email_template_wrapper_id: null,
    footer_offset: null,
    from_address: '',
    from_city: '',
    from_company: '',
    from_disclaimer: '',
    from_email: '',
    from_fax: '',
    from_phone: '',
    from_state: '',
    from_website: '',
    from_zip: '',
    header_logo: '',
    internal_template: null,
    invoice_guid: null,
    invoice_id: null,
    invoice_term: 30,
    is_site_form: false,
    license_link: null,
    license_term: 1,
    logo: '',
    operator_id: null,
    payment_method_id: null,
    payment_type: '',
    pdf_prepend: '',
    po_number: null,
    privacy_link: '',
    requestor_address: '',
    requestor_city: '',
    requestor_email: '',
    requestor_name: '',
    requestor_organization: '',
    requestor_phone: '',
    requestor_state: '',
    requestor_zip: '',
    service_landing: '',
    support_email: '',
    support_name: '',
    support_phone: '',
    tc_link: '',
    template_id: null,
    template_name: '',
    template_wrapper_id: null,
    template_wrapper_name: '',
    url_key: '',
    user_comment: '',
    fake_col:'',
};

export { initInvoiceState };