const Privacy = () => {

    return (
        <>                    
        <div className="lead">
            <h1>
                CIS Privacy Policy
            </h1>
        </div>
    
        <div className="page-header pt-3">
            <h3>1. Personal Information</h3>
        </div>
        <p>
            <strong>1.1</strong> We respect our users' privacy. intoCareers
            adheres to guidelines created by the Children's On-Line Privacy
            Protection Act of 1998 and the Family Educational Rights and
            Privacy Act of 1996. Except as stated herein, or as otherwise
            required by law, personally identifiable information is not
            disclosed to any other group or agency for any purpose, nor,
            except as necessary to correct system problems, is individual
            information looked at by staff of intoCareers.
        </p>

        <div className="page-header term">
            <h3>2. General Website Activity</h3>
        </div>
        <p>
            <strong>2.1</strong> We maintain logs of site usage in order to
            improve the site. We gather such in formation as how often
            content is accessed, frequency of responses for assessment
            questions, average length of user session, etc. This information
            is reported in aggregate and may be shared with others but cannot
            be used to identify individuals or the data they save in their
            portfolio.
        </p>

        <div className="page-header term">
            <h3>3. Institution-level Reports</h3>
        </div>
        <p>
            <strong>3.1</strong> Schools or agencies that make CIS available
            to their students or clients can receive information in the form
            of various reports. These reports are designed to help CIS
            Coordinators (i.e., individual at the institution responsible for
            providing access to CIS) evaluate use of the CIS by their
            students or clients and enhance their career development programs
            and services.
        </p>

        <div className="page-header term">
            <h3>4. Portfolios for students at K-12 Institutions</h3>
        </div>
        <p>
            <strong>4.1</strong> K-12 institutions are covered by the
            Family Educational Rights and Privacy Act of 1996 or FERPA.
            Additionally, CIS is designed in accord with the Children's On-Line Privacy Protection Act of 1998

        </p>
        <p>FERPA includes provisions that reflect the way CIS operates
            in K-12 institutions. First, portions of all student portfolios
            at a school are accessible to the CIS Coordinator for the
            institution and to designees of the CIS Coordinator. However,
            under FERPA, the Coordinator and/or the designees must have a “legitimate interest” to look at material in a student’s
            portfolio. CIS Coordinators and their designees can only view
            personal reflections, or notes, if the student is Opted-In. The
            CIS Coordinator may set a policy in which all students are Opted-In or leave the choice to the student. If the policy is set so
            that all students are Opted-In, the student will receive
            notification of this policy the first time they log in.</p>

        <p>Provisions of the Children's On-Line Privacy
            Protection Act of 1998 require verifiable parental consent in
            order to collect personal information on individuals under the
            age of 13 ( “child” ). Schools making CIS available to students
            under the age of 13 need to obtain written authorization from
            parents or legal guardians before a child creates an on-line
            portfolio. The parent/legal guardian must agree to the provisions
            of the CIS Privacy Policy. Parents may obtain access to their
            child's portfolio from the school's CIS Coordinator or by
            using the child's user name and password. CIS Coordinators
            can delete the child's portfolio if the parent wishes to
            prohibit future use of the portfolio. Student's wishing to delete
            their portfolios should contact the Site Administrator. CIS
            Coordinators may also request that the option to create a
            personal portfolio be disabled for all students at a school.</p>
            
        <p>We maintain portfolio data as long as the school or agency
            subscribes to CIS. A user may delete information from their portfolio at any time, and it
            will be removed from the CIS database. Users reserve the right to delete their entire portfolio via a request to intoCareers or their CIS Administrator. </p>

        <p>
        Schools or agencies that make CIS available to their students or
        clients can receive information in the form of various reports.
        These reports are designed to help CIS Coordinators (i.e.,
        individual at the institution responsible for providing access to
        CIS) evaluate use of the CIS by their students or clients and
        enhance their career development programs and services.</p>

        <div className="page-header term">
            <h3>5. Linked Sites</h3>
        </div>
        <p>
            <strong>5.1</strong> CIS contains many links to related
            information and modules throughout the system. CIS analysts have
            identified many other Internet sites that provide potentially
            valuable information. These sites may provide services requiring
            you to register and enter personal information such as schools
            that accept electronic applications or job search sites that
            store electronic resumes. We do not endorse the privacy policy of
            these sites each user must choose whether to use their services.
            <br /><br />
            NOTE: Links to external sites open in a new browser window, to
            return to CIS close the window
        </p>
      </>
    )
}

export { Privacy };