import { useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { submitOrder, triggerValidationAndFocus, reportInValidation } from '../../store/actions';

const SubmitOrder = () => {
    const dispatch = useDispatch();    
    const navigate = useNavigate();    
    const form = createRef();
    const {invoice, validationState, validationTestNumber, validationFocusNumber, submitting } = useSelector((state) => state.order);
    const goToLink = (url) => {
        window.open(url, '_blank');
    }

    const handleChange = (e) => {
        dispatch(reportInValidation( { termsAccepted: e.target.checked }))
    }

    const reportFormState = () => {
        dispatch(reportInValidation( { termsAccepted: form.current.checkValidity() }))
    }

    const focusValidation = () => {
        form.current.reportValidity();
    }


    const handleSubmit = () => {                
        const validationTest = Object.keys(validationState).reduce((memo, item) => {            
            return (memo && validationState[item]);
        }, true);
        if (validationTest) {
            // console.log(invoice.payment_method_id)
            dispatch(submitOrder());
        } else {
            dispatch(triggerValidationAndFocus());            
        }

    }

    useEffect(() => {
        if (validationTestNumber > 0) {
            reportFormState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationTestNumber]);
    
    useEffect(() => {
        if (validationFocusNumber > 0) {
            focusValidation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationFocusNumber]);

    useEffect(() => {
        if (invoice.invoice_guid) {
            navigate(`/invoice/${invoice.invoice_guid}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice.invoice_guid]); 

    return (
<form ref={form} onBlur={reportFormState} onSubmit={e => e.preventDefault()}>
    <div className="row pl-2 pr-2">
        <legend className="col-form-label col-sm-12 pt-0"><b>Terms</b></legend>
        <div className="form-check">

            <input id="termsAccepted" type="checkbox" className="form-check-input" required  onChange={handleChange} name="termsAccepted" />
      
            <label htmlFor="termsAccepted" className="form-check-label" >
                I confirm that I am an authorized purchaser and agree to all payment terms,
                &nbsp;
                {invoice.license_link && (
                    <>                    
                <span className={'decorated-link'} onClick={() => goToLink(invoice.license_link)}>
                   Licensing Terms,
                </span>
                &nbsp;
                </>
                )}
                 
                <span className={'decorated-link'} onClick={() => goToLink(invoice.tc_link)}>
                Terms & Conditions
                </span>
                
                &nbsp; and &nbsp;
                <span className={'decorated-link'} onClick={() => goToLink(invoice.privacy_link)}>
                Privacy Policy
                </span>
                
            </label>            
        </div>

    </div>
    
        <div className="pt-4 d-flex justify-content-around">                    
            <button 
                type="button"
                onClick={handleSubmit}
                disabled={submitting}
                className="btn btn-lg btn-primary btn-block"
                >
                {!!invoice.submitting ? (
                    <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                                        
                ) : (
                    <div>
                        Place Order
                    </div>
                )}
                    
                    
                </button>
            </div>
        </form>

    );
}

export { SubmitOrder };