const InvoiceRequestor = ({invoice}) =>  {
    return (
        <address>
            <div className="d-flex flex-column font-weight-light font-90">
                <h6 className="text-uppercase font-weight-bold">REQUESTED BY:</h6>
                <div>{invoice.requestor_organization}</div>
                <div>{invoice.requestor_name}</div> 
                <div>
                    <a href={`tel:${invoice.requestor_phone}`}>
                        <i className="fa fa-phone pr-1" aria-hidden="true"></i> {invoice.requestor_phone}
                    </a>
                </div>
                <div>
                    <a href={`mailto:${invoice.requestor_email}`}>
                        <i className="fa fa-envelope-o pr-1" aria-hidden="true"></i> {invoice.requestor_email}
                    </a>
                </div>
            </div>
        </address>
    )
} ;
export { InvoiceRequestor };