import React from 'react';
import { getSitesTotals } from '../../Utils/invoiceTotals';
import { formatCurrency } from "../../Utils/formatCurrency";

const CartList = ({ modules, invoice, siteOrdered }) => {

    const { sitesTax, sitesSum } = getSitesTotals(invoice, modules, siteOrdered);

    const fieldClasses = {
        product: 'col col-lg-4 col-md-6 col-sm-6 col-xs-12',
        yearlyLicense: 'col col-lg-3 col-md-2 col-sm-4 col-xs-4 d-none d-sm-block text-right', // hidden only on small
        enrollment: 'col col-lg-2 col-md-2 col-sm-4 col-xs-4 d-none d-sm-block text-center', // hidden only on small
        qty: 'col col-lg-1 col-md-1 col-sm-2 d-none d-sm-block text-right',
        total: 'col col-lg-2 col-md-2 d-sm-none d-md-block text-right'
    }

    /**for display  */
    const subTotal = (siteOrder) => {
        if (!siteOrder || !siteOrder.addOns) return 0;
        const addOnTotal = siteOrder.addOns.reduce((memo, item) => {
            return memo + item.price;
        }, 0);

        return addOnTotal + siteOrder.price;
    }

    return (
        <div className="card p-0" style={{ fontSize: 'smaller' }}>
            <div className="card-header">
                <div className="row" style={{ fontWeight: '700' }}>

                    <div className={fieldClasses.product}><span className="card-title">Product</span></div>
                    <div className={fieldClasses.yearlyLicense}><span className="card-title">Yearly License</span></div>
                    <div className={fieldClasses.enrollment}><span className="card-title">Enrollments/Users</span></div>
                    <div className={fieldClasses.qty}><span className="card-title">Qty</span></div>
                    <div className={fieldClasses.total}><span className="card-title">Total</span></div>
                </div>
            </div>
            <div className="card-body">
                {siteOrdered && siteOrdered.map((site, index) => (
                    <React.Fragment key={`module-${index}-key`}>
                        {index > 0 && <hr />}
                        <div className="row">
                            <div className={'font-weight-bold ' + fieldClasses.product + (index % 2 ? ' pt-2' : '')}>{site.schoolName}</div>
                        </div>
                        <div className="row">
                            <div className={'font-weight-bold ' + fieldClasses.product + (index % 2 ? ' pt-2' : '')}>{site.service_name}</div>
                            <div className={fieldClasses.yearlyLicense}>
                                {(site.price > 0 && (!site.is_variable_price || (
                                    site.appType === 'Individual')
                                )) ? (
                                    formatCurrency(site.price)
                                ) :
                                    <>

                                        {site.appType !== 'optional' &&
                                            <span>{formatCurrency(site.price_per_person)}/student</span>
                                        }

                                    </>
                                }
                            </div>
                            <div className={fieldClasses.enrollment}>
                                {site.enrollment > 0 &&
                                    <span>{site.enrollment}</span>
                                }
                            </div>
                            <div className={fieldClasses.qty}>
                                {site.price > 0 ? (
                                    <>
                                        1
                                    </>
                                ) : (
                                    <span> </span>
                                )}

                            </div>
                            <div className={fieldClasses.total}>
                                {(site.price > 0 && (!site.is_variable_price || site.appType === 'Individual')) ? (
                                    <>
                                        {formatCurrency(site.price)}
                                    </>
                                ) : 
                                    <>
                                        {site.appType !== 'optional' &&
                                            <span>{formatCurrency(site.price_per_person * site.enrollment)}</span>
                                        }
                                        </>
                                }

                            </div>
                        </div>
                        {site && site.addOns && site.addOns.map((service, serviceIndex) => (
                            <div className="row" key={`service-${serviceIndex}-key`}>
                                <div className={fieldClasses.product}>- {service.service_name}</div>
                                <div className={fieldClasses.yearlyLicense}>
                                    {formatCurrency(service.price)}
                                </div>
                                <div className={fieldClasses.enrollment}>

                                </div>
                                <div className={fieldClasses.qty}>1</div>
                                <div className={fieldClasses.total}>
                                    {formatCurrency(service.price)}
                                </div>
                            </div>
                        ))
                        }
                        {site &&
                            <div className="row" key={`service-${site.siteId}-key`}>
                                <div className='col text-right'>
                                    Subtotal:
                                </div>
                                <div className='col text-right'>
                                    {formatCurrency(subTotal(site))}
                                </div>
                            </div>
                        }
                    </React.Fragment>
                ))}
            </div>
            <div className="card-footer" style={{ fontWeight: '700' }}>
                <div className="row">
                    <div className="col col-lg-2 offset-lg-8 col-md-4 offset-md-4 col-sm-8">Years</div>
                    <div className="col col-lg-2 col-md-4 col-sm-4 text-right">x {invoice && invoice.license_term}</div>
                </div>

                <div className="row">
                    <div className="col col-lg-2 offset-lg-8 col-md-4 offset-md-4 col-sm-8">
                        Tax {invoice.tax_pct ? <span>({(invoice.tax_pct * 100).toFixed(2)}%)</span> : ''}</div>
                    <div className="col col-lg-2 col-md-4 col-sm-4 text-right">
                        {formatCurrency(sitesTax)}
                    </div>
                </div>

                <div className="row">
                    <div className="col col-lg-2 offset-lg-8 col-md-4 offset-md-4 col-sm-8">Total</div>
                    <div className="col col-lg-2 col-md-4 col-sm-4 text-right">

                        {formatCurrency(sitesSum)}
                    </div>
                </div>
            </div>
        </div>
    )
}


export { CartList };