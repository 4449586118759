import React from 'react';
import { getTotals } from '../../Utils/invoiceTotals';
import { formatCurrency } from "../../Utils/formatCurrency";

const InvoiceServices = ({ modules, invoice, siteOrdered }) => {

    const { subTotal, taxes, total } = getTotals(invoice, modules, siteOrdered)

    const fieldClasses = {
        product: 'col col-lg-4 col-md-6 col-sm-6 col-xs-12',
        yearlyLicense: 'col col-lg-3 col-md-2 col-sm-4 col-xs-4 d-none d-sm-block text-right', // hidden only on small
        enrollment: 'col col-lg-2 col-md-2 col-sm-4 col-xs-4 d-none d-sm-block text-center', // hidden only on small
        qty: 'col col-lg-1 col-md-1 col-sm-2 d-none d-sm-block text-right',
        total: 'col col-lg-2 col-md-2 d-sm-none d-md-block text-right'
    }

    return (
        <div className="card p-0" style={{ fontSize: 'smaller' }}>
            <div className="card-header">
                <div className="row" style={{ fontWeight: '700' }}>

                    <div className={fieldClasses.product}><span className="card-title">Product</span></div>
                    <div className={fieldClasses.yearlyLicense}><span className="card-title">Yearly License</span></div>
                    <div className={fieldClasses.enrollment}><span className="card-title">Enrollments/Users</span></div>
                    <div className={fieldClasses.qty}><span className="card-title">Qty</span></div>
                    <div className={fieldClasses.total}><span className="card-title">Total</span></div>
                </div>
            </div>
            <div className="card-body">
                {modules && modules.filter(item => !item.parent_template_service_id && item.quantity > 0).map((module, index) => (
                    <React.Fragment key={`module-${index}-key`}>
                        <div className="row">
                            <div className={'font-weight-bold ' + fieldClasses.product + (index % 2 ? ' pt-2' : '')}>{module.school_organization_name}</div>
                        </div>
                        <div className="row">

                            <div className={'font-weight-bold ' + fieldClasses.product + (index % 2 ? ' pt-2' : '')}>{module.service_name}</div>
                            <div className={fieldClasses.yearlyLicense}>
                                {module.price > 0 ? (
                                    formatCurrency(module.price)
                                    // <CurrencyFormat value={module.price | 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                ) : (
                                    <>
                                        <span> </span>
                                    </>
                                )}
                            </div>
                            <div className={fieldClasses.enrollment}>{module.enrollment}</div>
                            <div className={fieldClasses.qty}>
                                {module.price > 0 ? (
                                    <>
                                        {module.quantity}
                                    </>
                                ) : (
                                    <span> </span>
                                )}

                            </div>

                            <div className={fieldClasses.total}>
                                {module.price > 0 ? (
                                    <>
                                        {/* <CurrencyFormat value={module.quantity * module.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />   */}
                                        {formatCurrency(module.quantity * module.price)}
                                    </>
                                ) : (
                                    <span> </span>
                                )}

                            </div>
                        </div>
                        {modules && modules.filter(item => item.parent_template_service_id === module.template_service_id && item.quantity > 0).map((service, serviceIndex) => (
                            <div className="row" key={`service-${serviceIndex}-key`}>
                                <div className={fieldClasses.product}>- {service.service_name}</div>
                                <div className={fieldClasses.yearlyLicense}>

                                    {formatCurrency(service.price)}
                                </div>
                                <div className={fieldClasses.enrollment}></div>
                                <div className={fieldClasses.qty}>{service.quantity}</div>
                                <div className={fieldClasses.total}>
                                    {formatCurrency(service.quantity * service.price)}
                                </div>
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
            <div className="card-footer" style={{ fontWeight: '700' }}>
                <div className="row">
                    <div className="col col-lg-2 offset-lg-8 col-md-4 offset-md-4 col-sm-8">Subtotal</div>
                    <div className="col col-lg-2 col-md-4 col-sm-4 text-right">
                        {formatCurrency(subTotal)}
                    </div>
                </div>


                <div className="row">
                    <div className="col col-lg-2 offset-lg-8 col-md-4 offset-md-4 col-sm-8">Tax</div>
                    <div className="col col-lg-2 col-md-4 col-sm-4 text-right">
                        {formatCurrency(taxes)}
                    </div>
                </div>
                <div className="row">
                    <div className="col col-lg-2 offset-lg-8 col-md-4 offset-md-4 col-sm-8">Years</div>
                    <div className="col col-lg-2 col-md-4 col-sm-4 text-right">x {invoice && invoice.license_term}</div>
                </div>
                <div className="row">
                    <div className="col col-lg-2 offset-lg-8 col-md-4 offset-md-4 col-sm-8">Total</div>
                    <div className="col col-lg-2 col-md-4 col-sm-4 text-right">
                        {formatCurrency(total)}
                    </div>
                </div>

            </div>
        </div>
    )
}


export { InvoiceServices };