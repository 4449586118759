const InvoiceBilling = ({invoice}) =>  {
    return (
        <address>
            <div className="d-flex flex-column font-weight-light font-90">
                <h6 className="text-uppercase font-weight-bold">BILL TO:</h6>
                <div>{invoice.bill_organization}</div>
                <div>{invoice.bill_attn}</div>
                <div>{invoice.bill_address}</div>
                <div>{invoice.bill_city}, {invoice.bill_state} {invoice.bill_zip}</div>        
                <div>
                    <a href={`tel:${invoice.bill_phone}`}>
                        <i className="fa fa-phone pr-1" aria-hidden="true"></i> {invoice.bill_phone}
                    </a>
                </div>
                <div>
                    <a href={`mailto:${invoice.bill_email}`}>
                        <i className="fa fa-envelope-o pr-1" aria-hidden="true"></i> {invoice.bill_email}
                    </a>
                </div>
            </div>
        </address>
    )
} ;
export { InvoiceBilling };