
  import './styles/main.css';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ModuleChoose, Privacy, Terms, OrderDetail, UnknownForm, ModuleItem } from './components';
import { BootstrapForm, Services, Sites, 
    ShoppingCart, Checkout, Invoice, Legal, 
    BootstrapFormMN,
    ServicesMN, 
    ShoppingCartMN, 
    CheckoutMN
} from './containers';




function App () {
    return (
        <Router>
            <Routes>
                <Route path="legal" element={<Legal />}>
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="terms" element={<Terms />} />
                </Route>
                {/* <Route path="" element={<UnknownForm />} /> */}
                <Route path="" element={<Navigate to="/BootstrapFormMN" />} />
                <Route path="invoice/:invoiceGuid" element={<Invoice />} />

                <Route path=":urlKey" element={<BootstrapForm />}>
                    <Route path="services" element={<Services />}>
                        {/* <Route path="" element={<ModuleChoose />} /> */}
                        <Route path=":moduleId" element={<OrderDetail />} />
                    </Route>
                    <Route path="sites" element={<Sites />} />
                    <Route path="cart" element={<ShoppingCart />} />
                    <Route path="checkout" element={<Checkout />} />
                </Route>
                <Route path="mncis2" element={<BootstrapFormMN />}>
                    <Route path="services" element={<ServicesMN />}>
                        <Route path="" element={<ModuleChoose />} />
                        <Route path=":moduleId" element={<ModuleItem />} />
                    </Route>
                    <Route path="sites" element={<Sites />} />
                    <Route path="cart" element={<ShoppingCartMN />} />
                    <Route path="checkout" element={<CheckoutMN />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
