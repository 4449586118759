const getTotals = (invoice, modules) => {
  const subTotal = () => {
    if (!Array.isArray(modules)) {
      return 0;
    }
    return modules.reduce((memo, item) => {
      return memo + item.quantity * item.price * invoice.license_term;
    }, 0);
  };

  const taxes = () => {
    if (!invoice.tax_pct) {
      return 0;
    }
    //const myTotal = subTotal() * invoice.license_term;
    const mytaxes = subTotal() * (invoice.tax_pct ? invoice.tax_pct : 0);
    return mytaxes;
  };

  const total = () => {
    if (!invoice) {
      return 0;
    }
    return subTotal() + taxes();
  };

  return { subTotal: subTotal(), taxes: taxes(), total: total() };
};

const getSitesTotals = (invoice, modules, sites) => {
  const siteSubTotal = (siteOrder) => {
    if (!siteOrder || !siteOrder.addOns ) return 0;
    const addOnTotal = siteOrder.addOns.reduce((memo, item) => {
      return memo + item.price;
    }, 0);
    
    return addOnTotal + siteOrder.price;
  }

  const sitesTotal = () => {
    let total = 0;
    if (sites && sites.length > 0) {
      sites.forEach(order => {
        total += siteSubTotal(order);
      });
      return total * (+invoice.license_term);
    }
    return total;
  }

  const sitesTax = () => {
    if (!invoice.tax_pct) return 0;
    return sitesTotal() * invoice.tax_pct;
  }
  const sitesSum = () => {
    return sitesTotal() + sitesTax();
  }
  return { sitesTotal: sitesTotal(), siteSubTotal: siteSubTotal(), sitesTax: sitesTax(), sitesSum: sitesSum() };
};


export { getTotals, getSitesTotals };
