// import CurrencyFormat from 'react-currency-format';
import { removeSite } from '../../store/actions';
import { useDispatch } from 'react-redux';
import {formatCurrency} from "../../Utils/formatCurrency";

const SiteServices = ({ modules }) => {
    const dispatch = useDispatch();
    const handleDelete = (site) => {
        dispatch(removeSite(site.template_service_id));
    }
    return (
        <>
        <div className="row pb-2 pt-2 font-weight-bold">
                <div className="col">Site Name</div>
                <div className="col-md-2">Enrollment</div>
                <div className="col offset-md-1">Services</div>
        </div>

        {modules && modules.filter(item => !item.parent_template_service_id && item.quantity > 0).map((module, index) => (
            
            <div key={`selected-${index}-module`} className={`row pb-3 pt-3  ${index % 2 ? 'bg-light': ''}`}>
                <div className="col-md-1" onClick={() => handleDelete(module)}><i className="fa fa-minus-circle text-danger" aria-hidden="true"></i></div>
                <div className="col">{module.service_name}</div>
                <div className="col-md-2">
                    {/* <CurrencyFormat value={module.enrollment} displayType={'text'} thousandSeparator={true} />   */}
                    {formatCurrency(module.enrollment)}
                </div>
                <div className="col offset-md-1">
                    {modules && modules.filter(item => item.parent_template_service_id === module.template_service_id  && item.quantity > 0).map((service, serviceIndex) => (
                        <div key={`selected-${serviceIndex}-module`} className="d-flex flex-row justify-content-between">
                            <div>                            
                                {service.service_name}
                            </div>
                            <div>
                                {/* <CurrencyFormat value={service.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />                               */}
                                {formatCurrency(service.price)}
                            </div>
                        </div>
                    ))}
                </div>
                
            </div>
        ))}
        </>
    );
}

export { SiteServices };