import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import CurrencyFormat from 'react-currency-format';
import { formatCurrency } from "../../Utils/formatCurrency";
import { updateModule } from '../../store/actions';
const ModuleItem = () => {
    const { moduleId } = useParams();
    const dispatch = useDispatch();
    const services = useSelector((state) => state.order.modules.filter(x => x.parent_template_service_id === +moduleId ));
    const parentModule = useSelector((state) => state.order.modules.find(x => x.template_service_id === +moduleId));
    const invoice = useSelector((state) => state.order.invoice);

    const addExtension = (service) => {
        if (!parentModule.quantity) {
            dispatch(updateModule({...parentModule, quantity: 1 })); 
            dispatch(updateModule({...service, quantity: 1 })); 
        } else {
            dispatch(updateModule({...service, quantity: parentModule.quantity })); 
        }
    }
    const removeExtension = (service) => {
        dispatch(updateModule({...service, quantity: 0 }));
    }
    const increaseModule = () => {
        const quantity = parentModule.quantity +1;
        dispatch(updateModule({...parentModule, quantity })); 
        services.forEach(service => {
            if (service.quantity > 0) {
                dispatch(updateModule({...service, quantity })); 
            }
        })        
    }
    const decreaseModule = () => {
        const quantity = parentModule.quantity > 0 ? parentModule.quantity -1 : 0;
        dispatch(updateModule({...parentModule, quantity })); 
        services.forEach(service => {
            if (service.quantity > 0) {
                dispatch(updateModule({...service, quantity })); 
            }
        })
    }
    const optionalTotal = () => {
        return services.reduce((memo, item) => {
            return memo + item.quantity * item.price;
        }, 0)
    }
    
    const total = () => {
        return parentModule.price * parentModule.quantity * invoice.license_term + (optionalTotal() * invoice.license_term);
    }
    
    const serviceImageStyle = {
        height: '6rem'        
      };

    return (
        <>
            
        <div className="lead">
            {parentModule && parentModule.service_name}
        </div>

        <div className="mt-2"></div>

            { (parentModule && parentModule.service_id!==2) ? 

        <div className="card p-2">
        <div className="card-body">
        <div className="row border-bottom pb-2">
            <div className="col-6 d-none d-lg-block">
                <img src={parentModule && parentModule.service_logo_src} style={serviceImageStyle} alt={`logo for ${parentModule && parentModule.service_name} module`} />
            </div>
            <div className="col-md-auto"></div>

            
            <div className="col price">
                <div className="row">
                    <div className="input-group mb-3">

                        <div className="input-group-prepend">
                            <span onClick={decreaseModule} aria-label="decrease license order count for module" className="input-group-text"><i className="fa fa-minus-circle fa-1x" aria-hidden="true"></i></span>
                        </div>
                            
                        <input type="text" className="form-control" value={parentModule.quantity} style={{textAlign: 'center'}} readOnly aria-label="Total" />
                        <div className="input-group-append">
                                <span onClick={increaseModule} aria-label="increase license order count for module" className="input-group-text"><i className="fa fa-plus-circle fa-1x" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">Price</div>
                    <div className="col text-right">
                        {/* <CurrencyFormat value={parentModule.price | 0} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */}
                                       { formatCurrency(parentModule.price)}
                    </div>
                </div>
                <div className="row">
                    <div className="col">Quantity</div>
                    <div className="col text-right">{parentModule.quantity | 0}</div>  
                </div>
                <div className="row">
                    <div className="col">x Years</div>
                    <div className="col text-right">{invoice.license_term}</div>     
                </div>                 
                <div className="row border-top bg-light text-dark">
                    <div className="col">Optional</div>
                    <div className="col text-right">
                        {/* <CurrencyFormat value={optionalTotal()} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */}
                                        {formatCurrency(optionalTotal())}
                    </div>     
                </div>
                <div className="row border-top  bg-secondary text-white">
                    <div className="col">Total</div>
                    <div className="col text-right">
                        {/* <CurrencyFormat value={total()} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */}
                                        {formatCurrency(total())}
                    </div>     
                </div>                 
            </div>            
        </div>
        <div className="card-text p-2 d-flex-column">
            <small>{parentModule.description}</small>
        </div> 
         
        {services && services.length > 0 && (
            <>
            <div className="p-2">Optional Services</div>
            <ul className="list-group list-group-flush">
                {services.map((service, key) => (
                    <li key={key} className="list-group-item">
                        <div className="d-flex d-flex-row justify-content-between font-weight-light">                            
                            <div className="form-check">
                                {service.quantity ? (
                                    <i className="fa fa-check-square-o pr-2" onClick={() => removeExtension(service)} aria-hidden="true"></i>
                                ) : (
                                    <i className="fa fa-square-o pr-2" onClick={() => addExtension(service)} aria-hidden="true"></i>                   
                                )}
                            </div>
                            <div>
                                <small>{service.service_name}</small>
                            </div>
                            <div className="flex-grow-1"></div>
                            <div>
                                <small>
                                    {/* <CurrencyFormat value={service.price | 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />                                     */}
                                    {formatCurrency(service.price | 0)}
                                </small>
                            </div>
                        </div>                        
                    </li>
                ))}
            </ul>            
            </>
        )}

           
    

        </div>
        </div>

        :null}

        </>
    );
}

export { ModuleItem };


