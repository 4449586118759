import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInvoiceBatch,
  getCreditCardUrl,
  getCreditCardPaymentStatus,
} from "../store/actions";
import {
  InvoiceServices,
  CCModal,
  InvoiceBilling,
  InvoiceRequestor,
  InvoiceTerms,
} from "../components";

import { API_URL } from "../env";
//import CurrencyFormat from "react-currency-format";
const Invoice = () => {
  const { invoiceGuid } = useParams();
  const dispatch = useDispatch();
  const [showCCModal, updateShowCCModal] = useState(false);
  const [showPaymentButton, updateshowPaymentButton] = useState(false);
  const handleClose = () => updateShowCCModal(false);

  const { isLoading, invoice, modules, ccUrl, siteOrdered } = useSelector(
    (state) => state.invoice
  );
  // const { siteOrdered } = useSelector(
  //   (state) => state.siteOrdered
  // );
  
  useEffect(() => {
    dispatch(fetchInvoiceBatch(invoiceGuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceGuid]);

  const viewPdf = () => {
    window.open(`${API_URL}/api/pdf/stream/${invoiceGuid}`, "_blank");
  };

  const payCreditCard = () => {
    window.open(`${ccUrl.url}`, "_blank");
  };

  useEffect(() => {
    if (invoice.date_payment_confirmed) {
      updateShowCCModal(false);
      updateshowPaymentButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice.date_payment_confirmed]);

  useEffect(() => {
    if (invoice.payment_type === "credit" && !invoice.date_payment_confirmed) {
      updateshowPaymentButton(true);
      dispatch(getCreditCardUrl(invoiceGuid));
      updateShowCCModal(true);
    } 
    
    if (invoice.date_payment_confirmed) {
      updateshowPaymentButton(false);
    }

    //fetch credit card payment status, if payment is confirmed,
    //Pay via CC is disabled
    let pollCount = 0;
    const interval = setInterval(() => {
      pollCount += 1;
      if (pollCount === 300 || invoice.date_payment_confirmed) {
        clearInterval(interval);
        updateShowCCModal(false);
        updateshowPaymentButton(false);
      } else {
        dispatch(getCreditCardPaymentStatus(invoice.invoice_guid));
      }
    }, 30000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice.payment_type, invoice.date_payment_confirmed]);

  return (
    <div className="container">
      {/* <div>Invoice {invoiceGuid}</div> */}

      {isLoading.invoice || isLoading.modules ? (
        <div className="d-flex flex-column align-items-center">
          <span className="full-page-load">
            <div className="lds-facebook">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </span>
        </div>
      ) : (
        <>
          {false && (
            <CCModal
              show={showCCModal}
              handleClose={handleClose}
              iframeUrl={ccUrl.url}
              handleDownload={viewPdf}
            />
          )}

          <div className="d-flex align-items-center flex-column">
            <h1 className="mb-auto p-3">Thank you for your order! </h1>
            
            <div
              className="pt-3 col-md-6 col-sm-6 col-sm-offset-3 lead"
              style={{ textAlign: "center" }}
            >
              Your account will be renewed or activated upon receipt of payment
              and confirmation of organization information.
             
            </div>
            <div className="p-3" />

            <div className="row">
              <div className="md-auto">
                <button className="btn btn-secondary" onClick={viewPdf}>
                  <i className="fa fa-file-pdf-o fa-lg p-2"></i>
                  Download Invoice
                </button>
              </div>
              <div>
               
              </div>
              {showPaymentButton && (
                <div className="md-auto">
                  <button
                    className="btn btn-primary"
                    onClick={payCreditCard}
                  >
                    <i className="fa fa-credit-card fa-lg p-2"></i>
                    Pay via credit card
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="p-3" />

          <div className="d-flex d-flex-row justify-content-between flex-wrap pt-4">
            <InvoiceBilling invoice={invoice} />

            <div className="d-none d-lg-block">
              <InvoiceRequestor invoice={invoice} />
            </div>

            <InvoiceTerms invoice={invoice} />
          </div>

            <InvoiceServices invoice={invoice} modules={modules} siteOrdered ={siteOrdered}  />
        </>
      )}
    </div>
  );
};

export { Invoice };
