import { useEffect, createRef, useRef, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { reportInValidation, updateInvoiceLocal } from '../../store/actions';

const Requestor = (props ) => {
    const { cloneBilling, invoice, validationTestNumber, validationFocusNumber } = useSelector((state) => state.order, shallowEqual);
    const dispatch = useDispatch();
    const form = createRef();

    /**this portion to fix cursor jumping issue  */
    const { value, onChange } = props;
    const [cursorReq, setCursorReq] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        const input = ref.current;
        if (input) {
          setTimeout(()=>{
            input.setSelectionRange(cursorReq, cursorReq);
        }, 30);
        }
    }, [ref, cursorReq, value]);
    /**============================================ */

    const reportFormState = () => {
            dispatch(reportInValidation( { requestor: form.current.reportValidity() }));        
    }

    const focusValidation = () => {        
            form.current.checkValidity();
    }
    const handleRequestorChange = (e) => {
      if (!cloneBilling) {
        dispatch(updateInvoiceLocal({ [e.target.name]: e.target.value }));
        reportFormState();
      }
      /**save cursor position */
      setCursorReq(e.target.selectionStart);
      //update current cursor position
      onChange && onChange(e);
    }

    const handleRequestorCursorKeys = (e) => {
      setCursorReq(e.target.selectionStart - 1);
    
    };
    useEffect(() => {
        if (validationTestNumber > 0) {
            reportFormState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationTestNumber]);
    
    useEffect(() => {
        if (validationFocusNumber > 0) {
            focusValidation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationFocusNumber]);        

    return (
      <form
        ref={form}
        onBlur={reportFormState}
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="reqName">Requested By:</label>
              <input
                id="reqName"
                className="form-control"
                disabled={cloneBilling}
                name="requestor_name"
                required={true}
                ref={ref}
                value={invoice.requestor_name}
                onChange={handleRequestorChange}
                onKeyDown={handleRequestorCursorKeys}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="reqOrg">Requesting Organization:</label>
              <input
                id="reqOrg"
                className="form-control"
                disabled={cloneBilling}
                name="requestor_organization"
                required={true}
                ref={ref}
                value={invoice.requestor_organization}
                onChange={handleRequestorChange}
                onKeyDown={handleRequestorCursorKeys}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="form-group">
              <label htmlFor="reqEmail">Email:</label>
              <input
                id="reqEmail"
                className="form-control"
                disabled={cloneBilling}
                name="requestor_email"
                //type="email"
                ref={ref}
                required={true}
                value={invoice.requestor_email}
                onChange={handleRequestorChange}
                onKeyDown={handleRequestorCursorKeys}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="form-group">
              <label htmlFor="reqPhone">Phone:</label>
              <input
                id="reqPhone"
                name="requestor_phone"
                disabled={cloneBilling}
                className="form-control"
                required={true}
                ref={ref}
                value={invoice.requestor_phone}
                onChange={handleRequestorChange}
                onKeyDown={handleRequestorCursorKeys}
              />
              <input
                id="reqFake"
                name="fake_col"
                disabled={cloneBilling}
                className="form-control, hiddenInput"
                required={false}
                ref={ref}
                value={invoice.fake_col}
                onChange={handleRequestorChange}
                onKeyDown={handleRequestorCursorKeys}
              />
            </div>
          </div>
        </div>
      </form>
    );
}

export { Requestor };