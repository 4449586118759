import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateInvoiceLocal } from '../../store/actions';
const PaymentMethod = () => {
    const dispatch = useDispatch();
    const {invoice, payment_methods } = useSelector((state) => state.order);
    // const activePaymentMethod = payment_methods.find(invoice.payment_method_id)  || payment_methods[0];
    const [activePaymentMethod, updateActivePaymentMethod] = useState({});
    
    useEffect(() => {
        if (!invoice.payment_method_id) {
            const defaultPayment = payment_methods.find(x => x.default);
            dispatch(updateInvoiceLocal({payment_method_id: +defaultPayment.payment_method_id}));
            updateActivePaymentMethod(defaultPayment);
        } else {
            const newPaymentMethod = payment_methods.find(x => +x.payment_method_id === +invoice.payment_method_id);
            updateActivePaymentMethod(newPaymentMethod);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice.payment_method_id])

    const handlePaymentIdChange = (e) => {
        const newPaymentMethod = payment_methods.find(x => +x.payment_method_id === +e.target.value);
        dispatch(updateInvoiceLocal({payment_method_id: +e.target.value}));
        updateActivePaymentMethod(newPaymentMethod);
    }
    const handlePOChange = (e) => {        
        dispatch(updateInvoiceLocal({po_number: e.target.value}));
    }    
    return (

<form id="paymentMethodForm">
    <fieldset className="form-group">
        <div className="row">
            <legend className="col-form-label col-sm-12 pt-0"><b>Payment Method</b></legend>
             
          <div className="col-md-12 col-sm-6 pt-0 pb-2">
                {payment_methods.map((paymentMethod, index) => (
                    <div className="form-check" key={index}>                    
                        <input className="form-check-input" type="radio" onChange={handlePaymentIdChange} name="{{paymentMethod.payment_method_id}}" id={"payment_method_" + paymentMethod.payment_method_id} checked={invoice.payment_method_id === +paymentMethod.payment_method_id} value={paymentMethod.payment_method_id} />
                        <label className="form-check-label" htmlFor={"payment_method_" + paymentMethod.payment_method_id}>
                            {paymentMethod.payment_method}
                        </label>                    
                    </div>
                ))}
          

                
          </div>

          <div className="col-md-12 col-sm-6 ">                        
            {activePaymentMethod && (
                <>
                {activePaymentMethod.instructions}
                {(!!activePaymentMethod.is_po) && (
                    <div className="form-group">
                        <input 
                            className="form-control"
                            id="poNumber"
                            name="po_number"
                            onChange={handlePOChange}
                            aria-describedby="poNumber"
                            placeholder="PO Number" />
                        <small 
                            id="emailHelp" 
                            className="form-text text-muted">
                                Don't have a PO yet?  Email it to <a href={`mailto:${invoice.support_email}`}>{invoice.support_email}</a> upon receipt of invoice.
                        </small>
                    </div>
                )}

                {activePaymentMethod.payment_type === 'check' && (         
                    <>
                     <div className="text-muted small">
                        <small dangerouslySetInnerHTML={{ __html: invoice.from_company }}></small>
                        <div>{invoice.from_address}</div>
                        <div>{invoice.from_city}, {invoice.from_state} {invoice.from_zip}</div>
                    </div>
                    </>

                )}
                </>
            )}                
            
          </div>
        </div>
      </fieldset>
</form>

    );
}

export { PaymentMethod };