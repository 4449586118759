export const formatCurrencyObject = (num)=>{
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    if (!num || num===0) return "$ 0.00";
    return USDollar.format(num.value);
}
export const formatCurrency = (num) => {
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    if (!num || num === 0) return "$ 0.00";
    return USDollar.format(num);
}
