const InvoiceTerms = ({invoice}) =>  {
    const termsArray = [
        {
            name: 'Invoice #',
            value: invoice.invoice_label
        },
        {
            name: 'Invoice Date',
            value: invoice.date_first_invoice_safe
        },
        {
            name: 'Payment Terms',
            value: invoice.invoice_term + ' days'
        },
        {
            name: 'Due Date',
            value: invoice.date_due_safe
        },
        {
            name: 'Start Date',
            value: `July 1, ${+invoice.academic_year -1}`
        },
        {
            name: 'End Date',
            value: `June 30, ${+invoice.academic_year + (+invoice.license_term -1)}`
        }                                
    ];
    return (
        <address>
            <div className="d-flex flex-column font-weight-light font-90">
                <h6 className="text-uppercase font-weight-bold">License Terms:</h6>
                {termsArray.map((item, index) => (
                    <div key={`terms-${index}-item`} className="d-flex d-flex-row justify-content-between flex-wrap">
                        <div className="pr-3">{item.name}:</div>
                        <div className="pl-3 font-weight-bold font-90">{item.value}</div>                        
                    </div>                    
                ))}                    
            </div>
        </address>
    )
}

export { InvoiceTerms };