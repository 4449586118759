import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
//defined in .env file
import { CT_ORDER_URL } from '../../env';


const ServiceList = () => {
    const { modules, invoice } = useSelector((state) => state.order);

    const [selected, updateSelected] = useState();
    const handleSelect = (template_service_id, service_id) => {
        if (service_id === 2) {
            window.open(CT_ORDER_URL, '_blank');
        }
        else {
            updateSelected(template_service_id);
        }
    }

    const [width, setWidth] = useState(window.innerWidth);

    /**responsive slideshow images  */
    const slideNum = (width>1000) ? 5 :
        (width>500 && width<1000) ? 3 :
        (width > 330 && width < 500) ? 2 :1;

    /**This is the place to customize order button in slideshow
     * orgType: Junior, High, College, Individual and default
     */
    const priceNote =(module)=>{
         if (module!==null){
             return (<div className='priceTag'>Order</div>);
         }
    }


    useEffect(() => {
        /**responsive slideshow */
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
  
    return (
        <Slide slidesToScroll={1} slidesToShow={slideNum} indicators={true}>
            {modules.filter(item => !item.parent_template_service_id).map((module, index) => (
                <div key={`service-${index}-item`} className="p-2">
                    <Link to={`/${invoice.url_key}/services/${module.template_service_id}`} onClick={() => handleSelect(module.template_service_id, module.service_id)}>
                        <div className={'card ' + (selected === module.template_service_id ? 'bg-light' : '')} >
                            {module.quantity > 0 && (
                                <div className="card-img-overlay">
                                    <div className="sr-only">Module is in shopping cart</div>
                                    <i className="fa fa-lg fa-shopping-cart text-success"></i>
                                </div>
                            )}
                            <div className="card-body d-flex flex-column justify-content-between">
                                 
                                <div className="card-image">
                                       <div className="row ml-0">
                                        <img src={module.service_logo_src} alt={`${module.service_name}`} title={module.service_name} />
                                    </div>
                                </div>
                                <div className="slideShowPriceNote">
                                    <div className="priceTag pt-1">
                                        { priceNote(module)} 
                                    </div>
                                </div>
                              
                            </div> 
                           
                        </div>
                    </Link>
                </div>
            ))}
        </Slide>


    )
}
export { ServiceList }