// ORDER FORM ACTIONS

export const FETCH_FORM_REQUEST = 'FETCH_FORM_REQUEST';
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS';
export const FETCH_FORM_FAILURE = 'FETCH_FORM_FAILURE';
export const BAD_INVOICE_FORM = 'BAD_INVOICE_FORM';
export const UPDATE_INVOICE_LOCAL = 'UPDATE_INVOICE_LOCAL';

export const UPDATE_MODULE = 'UPDATE_MODULE';
export const ADD_SITE = 'ADD_SITE';
export const REMOVE_SITE = 'REMOVE_SITE';



export const TRIGGER_VALIDATION = 'TRIGGER_VALIDATION';
export const TRIGGER_VALIDATION_FOCUS = 'TRIGGER_VALIDATION_FOCUS';

export const UPDATE_VALIDATION_STATE = 'UPDATE_VALIDATION_STATE';

export const SET_CLONE_BILLING = 'SET_CLONE_BILLING';

export const SUBMIT_ORDER_REQUEST = 'SUBMIT_ORDER_REQUEST';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_FAILURE = 'SUBMIT_ORDER_FAILURE';


// INVOICE ACTIONS
export const FETCH_INVOICE_REQUEST = 'FETCH_INVOICE_REQUEST';
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_FAILURE = 'FETCH_INVOICE_FAILURE';

export const BAD_INVOICE_GUID = 'BAD_INVOICE_GUID';


export const FETCH_INVOICE_PAYMENTSTATUS_REQUEST = 'FETCH_INVOICE_PAYMENTSTATUS_REQUEST';
export const FETCH_INVOICE_PAYMENT_SUCCESS = 'FETCH_INVOICE_PAYMENT_SUCESS';
export const FETCH_INVOICE_PAYMENT_FAILURE = 'FETCH_INVOICE_PAYMENT_FAILURE';


export const FETCH_INVOICE_CC_URL_REQUEST = 'FETCH_INVOICE_CC_URL_REQUEST';
export const FETCH_INVOICE_CC_URL_SUCCESS = 'FETCH_INVOICE_CC_URL_SUCCESS';
export const FETCH_INVOICE_CC_URL_FAILURE = 'FETCH_INVOICE_CC_URL_FAILURE';


export const UPDATE_SITEORDERED ='UPDATE_SITEORDERED';
export const REMOVE_SITEORDERED = 'REMOVE_SITEORDERED';

export const ADD_OPTIONAL = 'ADD_OPTIONAL';
export const REMOVE_ADDON = 'REMOVE_ADDON';
export const UPDATE_LICENSE_YEAR ='UPDATE_LICENSE_YEAR';

// export const SUBMITMN_ORDER_REQUEST = 'SUBMITMN_ORDER_REQUEST';
// export const SUBMITMN_ORDER_SUCCESS = 'SUBMITMN_ORDER_SUCCESS';
// export const SUBMITMN_ORDER_FAILURE = 'SUBMITMN_ORDER_FAILURE';
